import { Map } from 'immutable'
import React, { Fragment, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import Deed from 'src/entities/deed/model'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import User from 'src/entities/user/model'
import { selectCurrentUser, selectUserBrand, selectUserLocations } from 'src/entities/user/selectors'
import { Role } from 'src/generated/graphql'
import { useLocation } from 'src/navigation'
import { State } from 'src/reducers'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import StatusBar from 'src/retired/blocks/StatusBar'
import {
  Action,
  ActivityIndicator,
  Alert,
  Button,
  Gradient,
  List,
  Loading,
  Radio,
  Spacing,
  Text,
} from 'src/retired/elements'
import { Body1, Body2, H4 } from 'src/retired/shared/Typography'
import VolunteerTimeOffSelector from 'src/retired/shared/VolunteerTimeOffSelector'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { getContrastColor, useInjectEpics, useInjectReducer } from 'src/utils'

import { cancelAction } from '../../../modules/DeedCancel/actions'
import cancelEpics from '../../../modules/DeedCancel/epics'
import cancelReducer from '../../../modules/DeedCancel/reducer'
import {
  getEndDateFormattedTime,
  getEventEndDateTime,
  getEventStartDateTime,
  getStartDateFormattedTime,
} from '../utils'
import { ShiftCheckbox } from '../components'

import { confirmAction, initAction } from './actions'
import { AnnouncementBanner } from './AnnouncementBanner/AnnouncementBanner'
import epics from './epics'
import reducer from './reducer'
import { selectError, selectLoading, selectSubmitting } from './selectors'

const eventTypes = ['Event', 'BaseEvent']

const styles = StyleSheet.create({
  content: {
    alignSelf: 'stretch',
    paddingHorizontal: 25,
    paddingBottom: 20,
    paddingTop: 20,
    marginTop: -5,
    minHeight: 100,
  },
  vtoContainer: {
    alignItems: 'center',
  },
  titleContainer: {
    width: '100%',
    height: 240,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 64,
  },
  title: {
    minHeight: 30,
    marginBottom: 25,
  },
})

const getRolesToDisplay = ({
  user,
  deed,
  isCancel,
  isMultipleShiftsFeatureEnabledForEvent,
}: {
  user: User | undefined
  deed: Deed | null | undefined
  isCancel: boolean
  isMultipleShiftsFeatureEnabledForEvent: boolean | undefined
}): Role[] => {
  if (!deed || !user) {
    return []
  }

  if (isCancel) {
    return deed.userRoles(user) || []
  }

  if (isMultipleShiftsFeatureEnabledForEvent) {
    return deed.roles.toArray() || []
  }

  return deed?.roleSpotsAvailable()?.toArray() || []
}

const getInitiallySelectedRoleIds = ({
  user,
  deed,
  isCancel,
}: {
  user: User | undefined
  deed: Deed | null | undefined
  isCancel: boolean
}): string[] => {
  if (isCancel) {
    return []
  }

  const userRoles = (user && deed?.userRoles(user.id)) || []
  const userRoleIds = userRoles.map((role) => role.id)

  return userRoleIds || []
}

const isOverlappingShift = ({
  role,
  deed,
  user,
  selectedRoles,
  isCancel,
}: {
  role: Role
  deed: Deed | null | undefined
  user: User | undefined
  selectedRoles: string[]
  isCancel: boolean
}) =>
  user &&
  deed &&
  selectedRoles.some((otherRoleId) => {
    if (isCancel) {
      return false
    }
    const isChecked = selectedRoles.includes(role.get('id'))
    if (!isChecked) {
      return false
    }

    const otherRole = deed?.roles?.find((r) => r.id === otherRoleId)

    // Ensure we're not comparing the role with itself
    if (role.get('id') === otherRole.get('id')) {
      return false
    }
    const roleStart = new Date(role.get('startingAt'))
    const roleEnd = new Date(role.get('endingAt'))
    const otherRoleStart = new Date(otherRole.get('startingAt'))
    const otherRoleEnd = new Date(otherRole.get('endingAt'))

    // Overlap Scenarios:
    const completeDateOverlap = roleStart <= otherRoleStart && roleEnd >= otherRoleEnd
    const startDateIsOverlapping = roleStart < otherRoleEnd && roleEnd >= otherRoleEnd
    const endDateIsOverlapping = roleStart <= otherRoleStart && roleEnd > otherRoleStart
    const exactMatch = roleStart.getTime() === otherRoleStart.getTime() && roleEnd.getTime() === otherRoleEnd.getTime()
    const enclosedByOther = roleStart >= otherRoleStart && roleEnd <= otherRoleEnd

    return completeDateOverlap || startDateIsOverlapping || endDateIsOverlapping || exactMatch || enclosedByOther
  })

const DeedConfirm: React.FC = () => {
  useInjectReducer({ key: 'deedConfirm', reducer })
  useInjectEpics({ key: 'deedConfirm', epics })
  useInjectReducer({ key: 'deedCancel', reducer: cancelReducer })
  useInjectEpics({ key: 'deedCancel', epics: cancelEpics })

  const { deed: paramsDeedId } = useParams<{ deed: string }>()
  const { t } = useTranslation('deedScreen')
  const dispatch = useDispatch()
  const { colors, metrics } = useDeedTheme()
  const location = useLocation()
  const isCancel = location.pathname.includes('/cancel')

  const deed = useSelector((state: State) => (paramsDeedId ? selectDeedById(state, paramsDeedId) : null))
  const user = useSelector(selectCurrentUser)
  const userBrand = useSelector(selectUserBrand)
  const skills = useSelector(selectSkills)
  const locations = useSelector(selectUserLocations)
  const loading = useSelector(selectLoading)
  const submitting = useSelector(selectSubmitting)
  const error = useSelector(selectError)

  const [selected, setSelected] = useState(Map())
  const [showInviteOverlay, setShowInviteOverlay] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const [volunteerTimeOffForm, setVolunteerTimeOffForm] = useState<any>({})
  const [allowSubmitVto, setAllowSubmitVto] = useState({})
  const [isCancelLoading, setIsCancelLoading] = useState(false)

  const isMultipleShiftsFeatureEnabledForEvent = user?.hasFeature('multipleShifts') && deed?.type === 'Event'
  const rolesToDisplay = getRolesToDisplay({ user, deed, isCancel, isMultipleShiftsFeatureEnabledForEvent })
  const initiallySelectedRoleIds = getInitiallySelectedRoleIds({ user, deed, isCancel })

  useEffect(() => {
    dispatch(initAction(paramsDeedId))
  }, [dispatch, paramsDeedId])

  useEffect(() => {
    setSelected(Map())
    setShowInviteOverlay(false)
  }, [deed])

  useEffect(() => {
    if (!selectedRoles?.length && initiallySelectedRoleIds?.length) {
      setSelectedRoles(initiallySelectedRoleIds)
    }
  }, [initiallySelectedRoleIds])

  if (error) {
    return <ErrorScreen>{t`common:anErrorOccurred`}</ErrorScreen>
  }
  if (loading || !deed || !user || !skills || !locations) {
    return (
      <div>
        <Loading />
      </div>
    )
  }
  if (!deed.type) {
    return <Redirect to={`/deeds/${paramsDeedId}`} />
  }
  if (deed.type === 'Campaign') {
    return <Redirect to={`/deeds/${paramsDeedId}/donate`} />
  }
  if (deed.type === 'Project' && deed.externalLink) {
    return <Redirect to={`/deeds/${paramsDeedId}/external`} />
  }
  const userLocation = locations.get(user.location)
  if (!user.phone && userLocation && userLocation.phone !== false) {
    return <Redirect to={`/register/phone/${paramsDeedId}`} />
  }
  if (user && (!user.consent || !user.consent.shareData)) {
    return <Redirect to={`/register/consent/${paramsDeedId}`} />
  }

  const userAttending = deed.isUserAttending(user)
  const userWaitlisted = deed.isUserWaitlisted(user)
  const isConfirmed = userAttending || userWaitlisted || deed.hasUserAppliedForRole(user)

  // Any `deed.formQuestion` or `form` that is not yet `submitted` is considered "pending"
  const hasPendingForms = Boolean(
    Number(deed.formQuestions?.length && deed.formQuestionsStatus !== 'submitted') +
      (deed.forms?.length ? deed.forms.filter((form) => form.formStatus !== 'submitted').length : 0)
  )

  if (!isConfirmed && hasPendingForms) {
    return <Redirect to={`/deeds/${paramsDeedId}/forms`} />
  }
  if (deed.startingAt && deed.startingAt < new Date()) {
    Alert.alert(t`deedAlreadyStarted`)
    return <Redirect to="/feed" />
  }

  const waitlist = deed.isFull() && !userAttending && deed.type !== 'Project'
  const startDateFormattedTime = getStartDateFormattedTime(deed)
  const endDateFormattedTime = getEndDateFormattedTime(deed)
  const brand = userBrand || deed?.partner
  const userSkills = user && user.get('skills')
  const firstSelectedRole = selectedRoles?.length ? deed.roles?.find((r) => r.id === selectedRoles[0]) : null
  const isSocialSharingDisabled = user?.organization?.settings?.disableSocialSharing
  const onlyNewSelectedRoleIds = selectedRoles.filter((item) => !initiallySelectedRoleIds.includes(item))

  const volunteerTimeOff = volunteerTimeOffForm.isVtoRequest && {
    deed: volunteerTimeOffForm.deed,
    status: volunteerTimeOffForm.status,
    duration: Number(volunteerTimeOffForm.hours * 60) + Number(volunteerTimeOffForm.minutes),
    date: volunteerTimeOffForm.date,
    userId: volunteerTimeOffForm.userId,
  }

  const toggleRole = (roleId: string) => {
    const currentSelectedRoles = selectedRoles
    if (currentSelectedRoles.includes(roleId)) {
      setSelectedRoles(
        isMultipleShiftsFeatureEnabledForEvent ? currentSelectedRoles.filter((role) => role !== roleId) : []
      )
    } else {
      setSelectedRoles(isMultipleShiftsFeatureEnabledForEvent ? [...currentSelectedRoles, roleId] : [roleId])
    }
  }

  const applyForRole = () => {
    if (selectedRoles?.length) {
      dispatch(
        confirmAction(
          deed.id,
          selected,
          [selectedRoles[0]],
          deed.type,
          volunteerTimeOff,
          user.organization?.settings?.disableSocialSharing
        )
      )
    } else {
      dispatch(showErrorAction(t('pleaseSelectARole', { context: deed.type !== 'Project' && 'shifts' })))
    }
  }

  const updateForm = (values: any) => {
    setVolunteerTimeOffForm(values)
  }

  const validateVtoForm = (value: any) => {
    setAllowSubmitVto(value)
  }

  const renderForeground = () => {
    if (eventTypes.includes(deed.type)) {
      return (
        <View style={styles.titleContainer}>
          <H4 weight="500" colour={colors.white} center style={styles.title} numberOfLines={2} marginBottom={32}>
            {deed.name}
          </H4>

          <Body2 colour={colors.white} center style={{ height: 20 }}>
            {t('date:weekdayDayMonthShort', {
              date: deed.virtual
                ? deed.startingAt || deed.createdAt
                : { value: deed.startingAt || deed.createdAt, timeZone: deed.timeZone },
            })}
          </Body2>

          <Body2 colour={colors.white} center style={{ height: 20 }}>
            {getEventStartDateTime(deed)} – {getEventEndDateTime(deed)}
          </Body2>

          <Body2 colour={colors.white} center numberOfLines={2}>
            {deed.location}
          </Body2>

          {!userAttending && !userWaitlisted && !isCancel ? (
            <Spacing marginTop={15}>
              <Body2 colour={colors.white} center>
                {waitlist
                  ? t`ifASpotBecomesAvailable`
                  : eventTypes.includes(deed.type) && !user.organization
                  ? `${t`ifYouCantMakeThiOpportunity`}`
                  : null}
              </Body2>
            </Spacing>
          ) : null}

          {!metrics.isSmall && deed?.creditAmountPerHour > 0 && !isCancel && (
            <AnnouncementBanner
              title={
                <Trans
                  t={t}
                  i18nKey={
                    user?.isEmployee() ? 'volunteerHourDonationCreditEmployee' : 'volunteerHourDonationCreditGuest'
                  }
                  values={{ organizationName: brand?.name }}
                  components={{
                    CurrencyFormat: (
                      <CurrencyFormat
                        amount={deed.creditAmountPerHour}
                        baseCurrency={deed.creditCurrencyCode}
                        process="round"
                        short
                      />
                    ),
                  }}
                />
              }
              content={t('volunteerHourCheckInReminder', { organizationName: brand?.name })}
              brand={brand}
            />
          )}
        </View>
      )
    }
    if (deed.type === 'Project') {
      return (
        <View style={styles.titleContainer}>
          <Text size={24} lineHeight={30} bold color={colors.white} center style={styles.title} numberOfLines={2}>
            {deed.name}
          </Text>
          {deed.virtual ? (
            <Text size={15} color={colors.white} center numberOfLines={2}>
              {t`locationVirtual`}
            </Text>
          ) : null}
          {deed.location && !deed.virtual ? (
            <Text size={15} color={colors.white} center numberOfLines={2}>
              {deed.location}
            </Text>
          ) : null}
        </View>
      )
    }
    return null
  }

  const Wrapper =
    showInviteOverlay && !metrics.isSmall
      ? ({ children }) => <View style={{ filter: 'blur(2px)', height: '100%', overflow: 'scroll' }}>{children}</View>
      : Fragment

  const newSelectedRoles = selectedRoles.filter((role) => !initiallySelectedRoleIds.includes(role))

  const isSubmitButtonDisabled = (deed?.roles?.size && !newSelectedRoles?.length) || !allowSubmitVto

  return (
    <Wrapper>
      <StatusBar />
      <ImageHeaderScrollView
        backTo={`/deeds/${deed.id}`}
        close
        image={deed.get('mainPicture')}
        color={brand?.brandColor || (eventTypes.includes(deed.type) ? colors.teal : colors.pink)}
        minOverlayOpacity={1}
        maxOverlayOpacity={1}
        maxHeight={280}
        minHeight={215}
        title={deed.name}
        subTitle={
          startDateFormattedTime
            ? `${startDateFormattedTime}${endDateFormattedTime ? ` – ${endDateFormattedTime}` : ''}`
            : ''
        }
        subTitle2={!deed.virtual && deed.location}
        renderForeground={renderForeground}
        renderHeader={false}
      >
        {rolesToDisplay?.length > 0 && (
          <Spacing marginBottom={15} marginTop={15} marginHorizontal={15}>
            <Body1 weight="500" center>
              {`${
                rolesToDisplay?.length > 1
                  ? `${t(`thisDeedHasMultipleRoles`, { context: deed.type !== 'Project' && 'shifts' })} `
                  : ''
              }${t('selectTheRole', { context: deed.type !== 'Project' && isCancel ? 'cancelShifts' : 'shifts' })}`}
            </Body1>
          </Spacing>
        )}
        {rolesToDisplay?.length ? (
          <List style={{ marginBottom: deed.type === 'Project' ? 100 : 0 }}>
            {isMultipleShiftsFeatureEnabledForEvent
              ? rolesToDisplay.map((role) => (
                  <ShiftCheckbox
                    role={role}
                    deed={deed}
                    checked={selectedRoles.includes(role.get('id'))} // has to be true because of 'noSpots'
                    onChange={() => toggleRole(role.get('id'))}
                    style={{ margin: '10px 20px' }}
                    disabled={
                      !isCancel && (!deed.roleSpotsAvailable(role) || initiallySelectedRoleIds.includes(role.get('id')))
                    }
                    key={role.get('id')}
                    isOverlappingShift={isOverlappingShift({
                      role,
                      user,
                      deed,
                      selectedRoles,
                      isCancel,
                    })}
                  />
                ))
              : rolesToDisplay.map((role) => (
                  <Radio
                    key={role.get('id')}
                    onChange={() => toggleRole(role.get('id'))}
                    checked={selectedRoles.includes(role.get('id'))}
                    style={{
                      backgroundColor: selectedRoles.includes(role.get('id')) ? colors.gray02 : 'transparent',
                    }}
                  >
                    <View style={{ width: '95%' }}>
                      <Body1 weight="500" lineHeight={24}>
                        {role.get('name')}
                        {role.get('startingAt') && role.get('endingAt') && (
                          <Body2>
                            {' '}
                            {t('date:weekdayDayMonthTimeShort', {
                              date: { value: role.get('startingAt'), timeZone: deed.timeZone },
                            })}{' '}
                            –{' '}
                            {t('date:time', {
                              date: { value: role.get('endingAt'), timeZone: deed.timeZone },
                            })}
                          </Body2>
                        )}
                        {deed.type === 'Project' && (
                          <Body2>
                            {role.get('skills')?.size > 0 && (
                              <>
                                {' '}
                                – {t`skillsRequired`}{' '}
                                {role
                                  .get('skills')
                                  .toArray()
                                  .map((skillId, index, roleSkills) => {
                                    const skill = skills.get(skillId)

                                    if (!skill) {
                                      return null
                                    }

                                    const userHasSkill = userSkills && userSkills.includes(skillId)

                                    return (
                                      <Body2 key={`${role.get('id')}${skillId}`} italic={userHasSkill}>
                                        {skill && skill.name.replace('-', '')}
                                        {roleSkills.length - 1 !== index ? ', ' : ''}
                                      </Body2>
                                    )
                                  })
                                  .filter(Boolean)}{' '}
                              </>
                            )}
                            – {t`availability`} {role.get('estimatedHoursMin')}
                            {role.get('estimatedHoursMax') !== role.get('estimatedHoursMin')
                              ? `-${role.get('estimatedHoursMax')}`
                              : ''}{' '}
                            {t('hour', { count: role.get('estimatedHoursMax') })} {t`over`} {role.get('durationMin')}
                            {role.get('durationMax') !== role.get('durationMin')
                              ? `-${role.get('durationMax')}`
                              : ''}{' '}
                            {role.get('durationMax') > 1
                              ? role.get('durationUnit')
                              : role.get('durationUnit').slice(0, -1)}
                          </Body2>
                        )}
                      </Body1>
                    </View>
                  </Radio>
                ))}
          </List>
        ) : null}

        <View style={{ minHeight: 100 }}>
          {eventTypes.includes(deed.type) ? (
            <>
              {!isMultipleShiftsFeatureEnabledForEvent ? (
                <Gradient height={20} startColor="#000" startOpacity={0.1} endColor="#fff" endOpacity={0} vertical />
              ) : null}
              {user.hasFeature('volunteerTimeOff') &&
                user?.volunteerTimeOffSummary?.balance &&
                !waitlist &&
                // We currently don't support vto with multiple shifts. Once we do, remove this check
                !isMultipleShiftsFeatureEnabledForEvent && (
                  <View style={(styles.vtoContainer, { minHeight: 120, alignItems: 'center' })}>
                    <VolunteerTimeOffSelector
                      handleSubmit={updateForm}
                      validateForm={validateVtoForm}
                      user={user}
                      deed={deed}
                      selectedRoleId={firstSelectedRole?.id}
                    />
                  </View>
                )}
              <View style={styles.content}>
                {waitlist && (
                  <View style={{ height: 150, justifyContent: 'flex-start' }}>
                    <Body1 lineHeight={24} center>
                      {t`whenSigningUp`}
                    </Body1>
                  </View>
                )}
              </View>
            </>
          ) : null}
        </View>
      </ImageHeaderScrollView>

      <Action>
        {eventTypes.includes(deed.type) ? (
          <Button
            disabled={isSubmitButtonDisabled}
            {...(brand?.brandColor && {
              style: {
                backgroundColor: brand?.brandColor,
                color: getContrastColor(brand?.brandColor),
              },
            })}
            color={waitlist || !deed.roles || deed.roles.size === 0 || selectedRoles?.length ? 'tertiary' : colors.gray}
            onPress={() => {
              if (!waitlist && deed.roles && deed.roles.size > 0 && !selectedRoles?.length) {
                dispatch(showErrorAction(t('pleaseSelectARole', { context: deed.type !== 'Project' && 'shifts' })))
                return
              }
              if (isCancel) {
                setIsCancelLoading(true)
                dispatch(cancelAction(deed.id, `/deeds/${deed.id}`, onlyNewSelectedRoleIds))
                return
              }

              dispatch(
                confirmAction(
                  deed.id,
                  selected,
                  onlyNewSelectedRoleIds,
                  deed.type,
                  volunteerTimeOff,
                  isSocialSharingDisabled
                )
              )
            }}
          >
            {submitting || isCancelLoading ? (
              <ActivityIndicator color="#fff" />
            ) : waitlist ? (
              t`confirmWaitlist`
            ) : isCancel ? (
              t`cancelSelectedShifts`
            ) : (
              t`confirm`
            )}
          </Button>
        ) : (
          <Button color="primary" disabled={isSubmitButtonDisabled} onPress={applyForRole}>
            {submitting ? (
              <ActivityIndicator color="#fff" />
            ) : firstSelectedRole?.autoApproval ? (
              t`confirm`
            ) : (
              t`common:Apply`
            )}
          </Button>
        )}
      </Action>
    </Wrapper>
  )
}

export default DeedConfirm
