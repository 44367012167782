import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { View } from 'react-primitives'
import { useSelector, useDispatch } from 'react-redux'
import uniqid from 'uniqid'
import { useTranslation, Trans } from 'react-i18next'
import {
  DonationProvider,
  FeeCoveredBy,
  PaymentMethodType,
  DEFAULT_DEED_FEE_CAP_USD,
  DEFAULT_APPLICATION_FEE_CORPORATE,
  DEFAULT_APPLICATION_FEE_EMPLOYEE,
} from '@joindeed/calculate-fees'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { CurrencyCode, CurrencySelectorTooltip, useCurrency } from 'src/containers/modules/CurrencyFormat'
import Layout from 'src/retired/blocks/Layout'
import { Alert, Button, Row, Spacing, Loading, TextField, Image, TabsPicker, ExternalLink } from 'src/retired/elements'
import GuestCheckout from 'src/containers/modules/GuestCheckout'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectOrganizationById, selectCompaniesWithSSO } from 'src/entities/organization/selectors'
import { useHistory, useParams, Redirect, useLocation } from 'src/navigation'
import { selectCurrentUser, selectUserBrand, selectUserLocations } from 'src/entities/user/selectors'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { H5, Body1, Body2 } from 'src/retired/shared/Typography'
import Tooltip from 'src/retired/elements/Tooltip'
import roundNumber from 'src/utils/roundNumber'
import makeGoBack from 'src/navigation/makeGoBack'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import PoweredBy from 'src/retired/blocks/PoweredBy'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { setUserContextAction } from 'src/sentry/actions'
import Platform from 'src/utils/Platform'
import { colors } from 'src/theme'
import { DonorPrivacy } from 'src/containers/screens/Donate/elements/Privacy'
import Organization from 'src/entities/organization/model'
import truthy from 'src/utils/truthy'
import { PageTitle } from 'src/components'
import type { State } from 'src/reducers'
import { calculateDeedBalanceProcessingFee } from 'src/containers/screens/Donate/calculateDeedBalanceProcessingFee'
import { calculateFeesWithHistory } from 'src/containers/screens/Donate/calculateFeesWithHistory'
import { usePaymentType } from 'src/containers/screens/Donate/paymentType'
import { useMatchData } from 'src/containers/screens/Donate/match'
import { useProviderData } from 'src/containers/screens/Donate/payment/useProviderData'

import * as homeActions from '../Home/actions'
import homeReducer from '../Home/reducer'
import homeEpics from '../Home/epics'

import { useIsPayrollEnabled } from './payroll/useIsPayrollEnabled'
import {
  BackAndTitle,
  Impact,
  TemplateOverlay,
  FeeCheckbox,
  ShareCheckbox,
  Privacy,
  BetterplaceForm,
  GiveIndiaForm,
  Sidebar,
  DonationAmountOptions,
  PaymentBlock,
} from './elements'
import { Donor } from './elements/DonorDetailsForm'
import { SHARE_NAME_ONLY_KEY, SHARE_NOTHING_KEY } from './actionNames'
import {
  selectLoading,
  selectLoadingError,
  selectDonationError,
  selectDonating,
  selectProviderDataByIdentifier,
} from './selectors'
import epics from './epics'
import reducer from './reducer'
import * as actions from './actions'
import { computeDonationProviderForNonprofit } from './computeDonationProviderForNonprofit'
import { PayrollDeductedDisplayText } from './elements/PayrollDeductedDisplayText/PayrollDeductedDisplayText'
import { mustDonateOffPlatform } from './utils/mustDonateOffPlatform/mustDonateOffPlatform'
import { getBaseCurrency, getNonprofitCountryCode, getNonprofits } from './nonprofits'
import { getDonationAmountOptions, getMaximumDonationAmount, getMinimumDonationAmount } from './donationLimits'
import { CampaignSelector, useCampaign } from './CampaignSelector'
import { BASE_SPACING, NOTICE_HIDE_IMPACT, SINGAPORE_CODE, DonationFrequency, type PaymentType } from './constants'
import { FrequencyInput, useFrequency } from './FrequencyInput'
import { usePayrollPaymentOption } from './PaymentOptionInput'
import { useCorporateProgram } from './corporateProgram/useCorporateProgram'
import { TaxReceipts } from './TaxReceipts'
import GiftAidCheckbox from './elements/giftAidCheckbox'

interface DonateButtonProps {
  color: string
  size: string
  disabled: boolean
  onPress: () => void
  fluid: boolean
  children: ReactNode
}
export const DonateButton = ({ children, ...props }: Partial<DonateButtonProps>): JSX.Element => {
  const { metrics } = useDeedTheme()
  return (
    <Button
      {...props}
      style={{
        paddingVertical: 0,
        paddingHorizontal: 20,
        marginTop: '20px',
        width: metrics.isSmall ? '100%' : '300px',
        height: metrics.isSmall ? '48px' : '40px',
      }}
    >
      {children}
    </Button>
  )
}

const roundedInputStyle = css`
  display: block;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin: 10px 0;
  padding: 0 20px;
  height: 40px;
`

const isDonationProviderAllowDedicationAndDesignation = (provider: DonationProvider | null): boolean => {
  if (!provider) {
    return false
  }

  const specificProviders = [
    DonationProvider.NFG,
    DonationProvider.GlobalGiving,
    DonationProvider.PayPal,
    DonationProvider.GiveIndia,
    DonationProvider.CAF,
  ]

  return specificProviders.includes(provider)
}

/** Screen */

const Donate = (): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  useInjectReducer({ key: 'donate', reducer })
  useInjectEpics({ key: 'donate', epics })
  useInjectReducer({ key: 'home', reducer: homeReducer })
  useInjectEpics({ key: 'home', epics: homeEpics })

  const {
    lite,
    deed: deedId = '',
    organization: organizationId = '',
  } = useParams<{
    lite?: string
    deed: string
    organization: string
  }>()
  const { search, pathname } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { images, metrics } = useDeedTheme()
  const { campaignIdFromUrl, currentCampaign, setSelectedCampaignId, selectedCampaignId } = useCampaign()
  const donationDate = currentCampaign?.pledgedDonationStartingAt || new Date()

  const type = deedId ? 'deed' : 'organization'

  const loading = useSelector(selectLoading)
  const loadingError = useSelector(selectLoadingError)
  const donationError = useSelector(selectDonationError)

  const deed = useSelector((state: State) => selectDeedById(state, deedId))
  const nonprofit: Organization | undefined = useSelector((state: State) =>
    selectOrganizationById(state, organizationId)
  )
  const donating = useSelector(selectDonating)
  const user = useSelector(selectCurrentUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const companiesWithSSO = useSelector(selectCompaniesWithSSO)

  const brand = useSelector(selectUserBrand)

  const [checkedCoverFee, setCheckedCoverFee] = useState(true)
  const [otherAmount, setOtherAmount] = useState('')
  const [otherActive, setOtherActive] = useState(false)
  const [selectedPrivacyType, setSelectedPrivacyType] = useState<null | 'shareAll' | 'shareNameOnly' | 'shareNothing'>(
    null
  )
  const [giftAidApplies, setGiftAidApplies] = useState(false)
  const [selectedDonorPrivacy, setDonorPrivacy] = useState<DonorPrivacy>(null)
  const [dedication, setDedication] = useState('')
  const [designation, setDesignation] = useState('')
  const [cardCountryCode, setCardCountryCode] = useState('US')
  const currency = useCurrency()
  const usdCurrency = useCurrency('USD' as CurrencyCode)
  const usdRates = usdCurrency?.rates
  const locations = useSelector(selectUserLocations)
  const [amount, _setAmount] = useState(25)
  const [transactionId, setTransactionId] = useState('')
  const [donationPaymentMethodState, setDonationPaymentMethod] = useState<PaymentMethodType>()

  const deedBalanceCurrency = useCurrency(user?.donationCreditsWallet?.currencyCode as CurrencyCode)
  const deedCreditAvailable = (user?.donationCreditsWallet?.balance || 0) > 0

  const nonprofits: Organization[] = getNonprofits({ deed, nonprofit })
  // @TODO Handle case where there are multiple currencies
  const localCurrency = getBaseCurrency({ deed, nonprofit }) // @NOTE-AT: Target Currency the nonprofit accepts

  const firstNonprofit: Organization | undefined = nonprofits[0]
  const supportsNonStripeProviders =
    computeDonationProviderForNonprofit(firstNonprofit, false) !== DonationProvider.Stripe

  const identifier = `${type}_${deedId || firstNonprofit?.donateViaNonprofitId}`
  const fetchedProviderData = useSelector((state: State) => selectProviderDataByIdentifier(state, identifier))

  const { isAnyPayrollEnabled, payrollPaymentOptions, isPayrollDefaultPaymentMethod } = useIsPayrollEnabled({
    user,
    supportsNonStripeProviders,
  })

  const { paymentType, setSelectedPaymentType, paymentTypeOptions } = usePaymentType({
    campaign: currentCampaign,
    isAnyPayrollEnabled,
    isPayrollDefaultPaymentMethod,
  })

  const donationPaymentMethod =
    paymentType === PaymentMethodType.Payroll
      ? PaymentMethodType.Payroll
      : // Default to DeedCredit if it's available
      deedCreditAvailable && !donationPaymentMethodState
      ? PaymentMethodType.DeedCredit
      : donationPaymentMethodState

  const [donor, setDonor] = useState<Donor>()
  const [donationAmountOptionsDefaults, setDonationAmountOptionsDefaults] = useState<number[]>([10, 25, 50, 100]) // @NOTE-AT: These increments are defined in USD
  // GiveIndia amount selected
  const [giveIndiaCommited, setGiveIndiaCommited] = useState<boolean>(false)
  const [checkoutAsGuest, setCheckoutAsGuest] = useState(false)
  const userCountryCode = user?.location && locations?.get(user.location)?.countryCode
  let ssoPrompt = null

  const showGuestCheckout = !isAuthenticated && !checkoutAsGuest

  const scrollViewEl = useRef(null)

  const { initialDonationProvider, providerData, nonprofitId } = useProviderData({
    nonprofits,
    donationPaymentMethod,
    deed,
    fetchedProviderData,
  })
  const { donationProvider } = providerData

  // CAF donations are always in USD because they are processed by PPGF US
  const reroutCurrencyToUSD = donationProvider === DonationProvider.CAF

  const baseCurrency = reroutCurrencyToUSD ? 'USD' : localCurrency

  const nonprofitCountryCode = getNonprofitCountryCode({
    nonprofit: firstNonprofit,
    donationProvider: initialDonationProvider,
  })

  // Show special info for users from Singapore when donating to non Singapore nonprofits
  const showLocationRestrictedInfo =
    initialDonationProvider === DonationProvider.PayPal &&
    userCountryCode === SINGAPORE_CODE &&
    nonprofitCountryCode !== SINGAPORE_CODE

  const corporateProgram = useCorporateProgram({
    user,
    nonprofits,
  })

  const { selectedPayrollPaymentOption, setSelectedPayrollPaymentOptionCode: setSelectedPayrollPaymentOption } =
    usePayrollPaymentOption(payrollPaymentOptions, paymentType)

  const { setSelectedFrequency, frequencyOptions, donationFrequency } = useFrequency({
    user,
    type,
    payPalModel: providerData.donationProvider === DonationProvider.PayPal ? providerData.payPalModel : null,
    isAnyPayrollEnabled,
    campaign: currentCampaign,
    paymentType,
    selectedPayrollPaymentOption,
  })
  const entity = deed || nonprofit

  // @NOTE-AC: It looks quite dangerous to treat missing rates as 1
  const currencyRate = currency?.rates?.[baseCurrency] || 1 // @NOTE-AT: Defines rates to convert user display currency into baseCurrency
  const userCurrencyUsdRate = usdCurrency?.rates?.[currency.code] || 1 // @NOTE-AT: Defines rates to convert user display currency into USD
  const usdRate = usdCurrency?.rates?.[baseCurrency] || 1 // @NOTE-AT: Defines rates to convert baseCurrency into USD

  const nonprofitNames = nonprofits.map((n) => n?.name).filter(truthy)

  const isCorporate = Boolean(user?.organization)

  // We can not set the default privacy in useState, as that value depends on `donationProvider`, which is not available initially
  const defaultDonorPrivacy = donationProvider === DonationProvider.NFG ? 'ProvideAll' : 'ProvideNameAndEmailOnly'
  const donorPrivacy = selectedDonorPrivacy || defaultDonorPrivacy

  const defaultPrivacyType = isCorporate ? 'shareNameOnly' : 'shareAll'
  const privacyType = selectedPrivacyType || defaultPrivacyType

  // Privacy format for our Donation model
  const privacy =
    privacyType === SHARE_NOTHING_KEY ? 'Anonymous' : privacyType === SHARE_NAME_ONLY_KEY ? 'Limited' : 'Public'

  const amountSelected = roundNumber((amount || parseFloat(otherAmount) || 0) / currencyRate, true)
  const isFundraiser = Boolean(deed) && Boolean(!nonprofit)

  const isRecurring = donationFrequency !== DonationFrequency.Onetime

  /** Fees */
  const applicationFees = {
    employee:
      corporateProgram?.metadata?.applicationFees?.employee ??
      user?.organization?.settings?.applicationFees?.employee ??
      DEFAULT_APPLICATION_FEE_EMPLOYEE,
    corporate:
      corporateProgram?.metadata?.applicationFees?.corporate ??
      user?.organization?.settings?.applicationFees?.corporate ??
      DEFAULT_APPLICATION_FEE_CORPORATE,
  }

  const applicationFeeSetting: number =
    donationPaymentMethod && [PaymentMethodType.Payroll, PaymentMethodType.DeedCredit].includes(donationPaymentMethod)
      ? applicationFees.corporate
      : applicationFees.employee

  const applicationFeeAmountCap = (user?.organization?.settings?.deedFeeCapUSD || DEFAULT_DEED_FEE_CAP_USD) / usdRate

  const { totalFeeAmount, grossAmount, netAmount, feeCalculationErrored, feesHistoryItem } = calculateFeesWithHistory({
    amount: amountSelected,
    coverFeesParam: checkedCoverFee,
    donationProvider: donationProvider || DonationProvider.PayPal,
    nonprofitCountryCode,
    cardCountryCode,
    donationPaymentMethod,
    isRecurring,
    user,
    applicationFeeSetting,
    applicationFeeAmountCap,
    feeCoveredBy: FeeCoveredBy.User,
  })
  const hasFees = totalFeeAmount > 0

  const matchData = useMatchData({
    entity,
    netAmount,
    user,
    baseCurrency,
    currency,
    donationPaymentMethod,
    currencyRate,
  })

  const setAmount = (value: number) => {
    _setAmount(value)
    matchData?.setDesiredMatchAmount(undefined)
  }

  useEffect(() => {
    if (firstNonprofit?.donateViaNonprofitId) {
      setDesignation(t('designationForDonateViaNonprofit', { child: firstNonprofit?.name || t`child` }))
    }
  }, [firstNonprofit])

  useEffect(() => {
    if (!campaignIdFromUrl) {
      dispatch(homeActions.fetchCampaignsAction())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(actions.initAction(deedId || organizationId, type, campaignIdFromUrl))
  }, [type, deedId, organizationId, campaignIdFromUrl])

  useEffect(() => {
    if (donor) {
      dispatch(
        setUserContextAction({
          name: `${donor.firstName} ${donor.lastName}`,
          email: donor.email,
        })
      )
    }
  }, [donor])

  useEffect(() => {
    if (user) {
      setTransactionId(uniqid(`${user.id}--`))
    }
  }, [donationError, user])

  useEffect(() => {
    const donationAmountOptionsOverride =
      deed?.donationAmountOptions ||
      currentCampaign?.donationAmountOptions ||
      currentCampaign?.company?.donationAmountOptions ||
      (user?.isEmployee() && user.organization.donationAmountOptions)
    if (donationAmountOptionsOverride && donationAmountOptionsOverride?.length > 0) {
      setDonationAmountOptionsDefaults(donationAmountOptionsOverride)
      setAmount(donationAmountOptionsOverride[1])
    }
  }, [currentCampaign, user])

  useEffect(() => {
    if (donationError && showLocationRestrictedInfo) {
      dispatch(showErrorAction(t`anErrorOccurredRestrictedLocation`))
    }
  }, [donationError, showLocationRestrictedInfo])

  if (
    !deed?.optedIn &&
    deed?.partner &&
    !deed?.public &&
    (deed.partner.id !== user?.organization?.id || user?.status !== 'accepted')
  ) {
    return <ErrorScreen>{t`fundraiserRestricted`}</ErrorScreen>
  }

  if (loadingError) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }

  if (loading || !entity || !usdRates?.USD || (isAuthenticated && !user)) {
    return !loading && user ? <ErrorScreen>{t`common:anErrorOccurred`}</ErrorScreen> : <Loading />
  }

  const goBackUrl = deedId
    ? `${lite ? '/lite' : ''}/deeds/${deedId}${campaignIdFromUrl ? `?campaign=${campaignIdFromUrl}` : ''}`
    : campaignIdFromUrl
    ? `${lite ? '/lite' : ''}/campaign/${campaignIdFromUrl}`
    : `${lite ? '/lite' : ''}/organization/${organizationId}`
  const inactiveFundraiser = deed?.isOngoing() === false
  if (inactiveFundraiser) {
    Alert.alert(deed.isPast() ? t`fundraiserHasEnded` : t`fundraiserHasNotStarted`)
    return <Redirect to={goBackUrl} />
  }
  const goBack = giveIndiaCommited
    ? () => {
        setGiveIndiaCommited(false)
      }
    : makeGoBack(history, goBackUrl, !!campaignIdFromUrl && !deed)

  // Redirect to login screen if not authenticated, not in "lite" mode, if deed is not public, and if not a nonprofit donate page
  if (!isAuthenticated && !lite && !deed?.public && !organizationId) {
    dispatch(setLocalSettingAction('previousLocation', goBackUrl))
    if (window?.sessionStorage) {
      window.sessionStorage.setItem('previousLocation', goBackUrl)
    }
    return <Redirect to="/login" />
  }

  if (initialDonationProvider === DonationProvider.Betterplace && !deedId) {
    Alert.alert(t`cannotDonateDirectly`)
    return <Redirect to={goBackUrl} />
  }

  if (
    (!firstNonprofit?.canDonate() && initialDonationProvider !== DonationProvider.Betterplace) ||
    initialDonationProvider === null
  ) {
    Alert.alert(t`organizationUnableToAcceptDonations`)
    return <Redirect to={goBackUrl} />
  }

  // If a user cannot donate in specific region, we ask them to make an offplatform donation
  if (firstNonprofit?.id && mustDonateOffPlatform({ userCountryCode, donationProvider })) {
    history.replace(`/organization/${firstNonprofit.id}/off-platform-donation`)
  }

  const minimumDonation = getMinimumDonationAmount({
    donationProvider,
    usdRate,
    deed,
    // Temporary: One Dollar Minimum Payroll based Donation [sc-62411]
    isPayrollPaymentSelected: paymentType === PaymentMethodType.Payroll,
  })

  const maximumDonation = getMaximumDonationAmount({
    donationProvider,
    usdRate,
    user,
    partner: currentCampaign?.company || deed?.partner,
  })

  const donationAmountOptions = getDonationAmountOptions({ userCurrencyUsdRate, donationAmountOptionsDefaults })

  if (!donationAmountOptions.includes(amount) && amount !== 0 && (!otherActive || !otherAmount)) {
    setAmount(donationAmountOptions[1])
  }

  const amountValid = !feeCalculationErrored && minimumDonation <= grossAmount && grossAmount <= maximumDonation
  // eslint-disable-next-line no-unsafe-optional-chaining
  const deedBalanceAmountSelected = roundNumber(amountSelected * deedBalanceCurrency?.rates?.[baseCurrency], true)
  const deedBalanceAmountSelectedValid = amountValid && Number.isFinite(deedBalanceAmountSelected)

  const nonprofitNamesFormatted =
    nonprofitNames.length > 1
      ? `${nonprofitNames.slice(0, -1).join(', ')} & ${[...nonprofitNames].pop()}`
      : nonprofitNames[0]

  if (!isAuthenticated) {
    const emailHost = donor?.email && donor.email.split('@')[1]

    if (emailHost) {
      companiesWithSSO.forEach((company) => {
        if (company.emailDomains.includes(emailHost) && !ssoPrompt) {
          ssoPrompt = setTimeout(
            () => {
              Alert.alert(t`loginOrRegister`, t('toLogInWithCompany', { companyName: company.name }), [
                { text: t`common:Cancel`, style: 'cancel' },
                {
                  text: t`common:Accept`,
                  onPress: () => history.push(`/login/sso/${company.id}#${donor?.email}`),
                },
              ])
              ssoPrompt = null
            },
            Platform.OS === 'web' ? 50 : 500
          )
        }
      })
    }
  }

  const leftColumn = giveIndiaCommited ? (
    <GiveIndiaForm
      deed={deed}
      nonprofitId={nonprofitId}
      amount={grossAmount}
      designation={designation}
      dedication={dedication}
      privacy={privacy}
      donorPrivacy={donorPrivacy}
      donor={donor}
      setGiveIndiaCommited={setGiveIndiaCommited}
      campaign={currentCampaign?.id}
      feeCovered={!!user?.feeCovered}
      scrollViewEl={scrollViewEl}
    />
  ) : (
    <>
      {isAnyPayrollEnabled && paymentTypeOptions.length > 1 && (
        <Spacing marginBottom={32}>
          <H5 weight="500" marginBottom={BASE_SPACING}>{t`payWith`}</H5>

          <TabsPicker
            options={[
              { name: t`otherPayment`, value: 'Other' },
              { name: t`payroll`, value: PaymentMethodType.Payroll },
            ]}
            selected={paymentType}
            onOptionSelect={(value) => setSelectedPaymentType(value as PaymentType)}
          />
        </Spacing>
      )}

      <View>
        <Row style={{ marginBottom: BASE_SPACING, alignItems: 'center' }}>
          <H5 weight="500" marginRight={8}>{t`amount`}</H5>
          <CurrencySelectorTooltip text={t`changeMyCurrency`} />
        </Row>
      </View>

      <DonationAmountOptions
        donationAmountOptions={donationAmountOptions}
        amount={amount}
        otherActive={otherActive}
        otherAmount={otherAmount}
        baseCurrency={baseCurrency}
        minimumDonation={minimumDonation}
        maximumDonation={maximumDonation}
        amountValid={amountValid}
        setOtherAmount={setOtherAmount}
        setOtherActive={setOtherActive}
        setAmount={setAmount}
      />

      {hasFees && !!amountSelected && !user?.feeCovered && (
        <View>
          <FeeCheckbox
            baseCurrency={baseCurrency}
            donationAmount={amountSelected}
            checked={checkedCoverFee}
            setChecked={setCheckedCoverFee}
            nonprofitNames={nonprofitNames}
          />
        </View>
      )}

      {!NOTICE_HIDE_IMPACT && (
        <>
          <Spacing marginBottom={BASE_SPACING * 0.75} />
          <Row>
            <Impact />
          </Row>
        </>
      )}

      {frequencyOptions.length > 1 && (
        <FrequencyInput
          frequencyOptions={frequencyOptions}
          setDonationFrequency={setSelectedFrequency}
          donationFrequency={donationFrequency}
          paymentType={paymentType}
        />
      )}

      {/* TODO: Use real dates from Payroll API */}
      {paymentType === PaymentMethodType.Payroll && (
        <Body1 colour={colors.grayDark} marginTop={BASE_SPACING * 0.75}>
          <PayrollDeductedDisplayText donationFrequency={donationFrequency} donationDate={donationDate} />
        </Body1>
      )}

      <Spacing marginBottom={BASE_SPACING} />

      <H5 weight="500" marginBottom={BASE_SPACING * 0.5}>
        {t`privacy`} <Tooltip title={t`privacyTooltipTitle`}>{t`privacyTooltipText`}</Tooltip>
      </H5>

      <View>
        <ShareCheckbox
          isCorporate={isCorporate}
          selected={privacyType}
          togglePrivacyType={(key: 'shareAll' | 'shareNameOnly' | 'shareNothing') => setSelectedPrivacyType(key)}
        />
      </View>

      <Spacing marginBottom={BASE_SPACING} />

      <H5 weight="500" marginBottom={BASE_SPACING * 0.5}>
        {t('shareWithTheOrganization', { count: nonprofits.length })}
      </H5>

      <View>
        <Privacy providerData={providerData} selected={donorPrivacy} onChange={setDonorPrivacy} />
      </View>

      <CampaignSelector
        urlCampaignId={campaignIdFromUrl}
        nonprofitId={firstNonprofit?.id}
        selectedCampaignId={selectedCampaignId}
        setSelectedCampaignId={setSelectedCampaignId}
        fundraiserCampaignIds={deed?.campaigns}
      />

      {isDonationProviderAllowDedicationAndDesignation(donationProvider) && (
        <>
          <H5 weight="500" marginTop={BASE_SPACING}>
            {t`dedicate`} <Tooltip title={t`dedication`}>{t`toMakeADonation`}</Tooltip>
          </H5>
          <TextField
            placeholder={t`optionalDedicationText`}
            onChangeText={(_name: string, value: string) => setDedication(value)}
            value={dedication}
            name="dedication"
            maxLength={100}
            style={roundedInputStyle}
          />
        </>
      )}

      {!isFundraiser &&
        !firstNonprofit.donateViaNonprofitId &&
        isDonationProviderAllowDedicationAndDesignation(donationProvider) && (
          <>
            <H5 weight="500" marginTop={BASE_SPACING / 2}>
              {t`designate`} <Tooltip title={t`designation`}>{t`youMayChooseToEnterADescription`}</Tooltip>
            </H5>
            <TextField
              placeholder={t`optionalDesignationText`}
              onChangeText={(_name: string, value: string) => setDesignation(value)}
              value={designation}
              name="designation"
              maxLength={100}
              style={roundedInputStyle}
            />
          </>
        )}
    </>
  )

  return (
    <>
      <PageTitle title={t('donateToSupport', { name: entity.name })} />

      {showGuestCheckout && (
        <GuestCheckout
          partner={deed?.partner}
          secondaryAction="AsGuest"
          handleModalDismissed={() => setCheckoutAsGuest(true)}
          onRedirect={() => {
            const currentLocation = `${pathname}${search}`

            dispatch(setLocalSettingAction('previousLocation', currentLocation))
            if (window?.sessionStorage) {
              window.sessionStorage.setItem('previousLocation', currentLocation)
            }

            // We don't want to be interrupted by any of the onboarding steps here
            dispatch(setLocalSettingAction('expediteOnboarding', true))
            if (window?.sessionStorage) {
              window.sessionStorage.setItem('expediteOnboarding', true)
            }
          }}
        />
      )}

      <TemplateOverlay donating={donating} />

      <Layout narrow padding withoutHeader scrollRef={scrollViewEl}>
        {/* without this View flexGrow inside Layout stretches following content */}
        <View>
          {donationProvider === DonationProvider.NFG && (
            <Image
              style={{
                width: 240,
                height: 30,
                position: 'absolute',
                right: 0,
                bottom: 33,
              }}
              source={images.NFGLogo}
            />
          )}

          {donationProvider === DonationProvider.GlobalGiving && (
            <View
              style={[
                css`
                  position: absolute;
                  right: 0;
                  bottom: 33px;
                `,
              ]}
            >
              <PoweredBy type="gg" />
            </View>
          )}

          <Spacing marginTop={BASE_SPACING} />

          {!!currentCampaign && <Body2 marginLeft={76}>{currentCampaign.title}</Body2>}

          <Row>
            <BackAndTitle title={t('donateToSupport', { name: entity.name })} onBackPress={goBack} />
          </Row>

          <Spacing marginTop={12} />

          <Spacing marginTop={BASE_SPACING} />

          <View>
            {donationProvider === DonationProvider.Betterplace && paymentType !== PaymentMethodType.Payroll && (
              <BetterplaceForm deed={deed} />
            )}

            {donationProvider !== DonationProvider.Betterplace && (
              <>
                <Spacing marginBottom={24} />

                <Row style={{ flexDirection: metrics.screenWidth > 815 ? 'row' : 'column-reverse' }}>
                  <View
                    style={
                      metrics.screenWidth > 815
                        ? { width: 'calc(100% - 320px)', paddingRight: 30, maxWidth: giveIndiaCommited ? 539 : '100%' }
                        : { maxWidth: giveIndiaCommited ? 539 : 'initial' }
                    }
                  >
                    {leftColumn}
                    <Spacing marginBottom={BASE_SPACING} />
                  </View>

                  <View
                    style={{ flexGrow: giveIndiaCommited ? 0 : 1, width: metrics.screenWidth > 815 ? 320 : '100%' }}
                  >
                    <Sidebar
                      matchData={matchData}
                      netAmount={netAmount}
                      baseCurrency={baseCurrency}
                      currency={currency}
                      processingFee={totalFeeAmount}
                      total={grossAmount}
                      donationPaymentMethod={donationPaymentMethod}
                      nonprofits={nonprofits}
                      coverFees={!hasFees || checkedCoverFee}
                      donationFrequency={donationFrequency}
                      donationDate={donationDate}
                      frequencyOptions={frequencyOptions}
                      paymentType={paymentType}
                      nonprofitNamesFormatted={nonprofitNamesFormatted}
                      feeCoveredSetting={!hasFees || !!user?.feeCovered}
                      onDonationFrequencyChange={setSelectedFrequency}
                      brand={brand || deed?.partner}
                      applicationFeeSetting={applicationFeeSetting}
                      usdRates={usdRates}
                      payrollPaymentOptions={payrollPaymentOptions}
                      selectedPayrollPaymentOption={selectedPayrollPaymentOption}
                      onPayrollPaymentOptionChange={setSelectedPayrollPaymentOption}
                    />
                  </View>
                </Row>

                {paymentType !== PaymentMethodType.Payroll && !giveIndiaCommited && (
                  <>
                    <View style={metrics.screenWidth > 815 ? { width: 'calc(100% - 320px)', paddingRight: 30 } : {}}>
                      <GiftAidCheckbox
                        nonprofit={firstNonprofit}
                        selected={giftAidApplies}
                        setGiftAidApplies={setGiftAidApplies}
                      />
                    </View>
                    <Spacing marginBottom={BASE_SPACING * 0.75} />
                    <PaymentBlock
                      giftAidApplies={giftAidApplies}
                      providerData={providerData}
                      fetchedProviderData={fetchedProviderData}
                      showLocationRestrictedInfo={showLocationRestrictedInfo}
                      lite={!!lite}
                      total={grossAmount}
                      baseCurrency={baseCurrency}
                      entity={entity}
                      deed={deed}
                      nonprofitId={nonprofitId}
                      nonprofits={nonprofits}
                      donationFrequency={donationFrequency}
                      donationProvider={donationProvider}
                      supportsNonStripeProviders={supportsNonStripeProviders}
                      donor={donor}
                      amountSelected={amountSelected}
                      amountValid={amountValid}
                      nonprofitCountryCode={nonprofitCountryCode}
                      checkedCoverFee={checkedCoverFee}
                      donorPrivacy={donorPrivacy}
                      privacy={privacy}
                      designation={designation}
                      dedication={dedication}
                      cardCountryCode={cardCountryCode}
                      campaignId={currentCampaign?.id}
                      deedBalanceProcessingFee={calculateDeedBalanceProcessingFee({
                        amount: amountSelected,
                        cardCountryCode,
                        coverFeesParam: checkedCoverFee,
                        donationProvider,
                        isRecurring,
                        nonprofitCountryCode,
                        user,
                        applicationFeeSetting: applicationFees.employee,
                        applicationFeeAmountCap,
                      })}
                      deedBalanceAmountSelectedValid={deedBalanceAmountSelectedValid}
                      ssoPrompt={ssoPrompt}
                      setDonor={setDonor}
                      setCardCountryCode={setCardCountryCode}
                      setDonationPaymentMethod={setDonationPaymentMethod}
                      setGiveIndiaCommited={setGiveIndiaCommited}
                      donateWithDeedBalance={() =>
                        dispatch(
                          actions.donateAction({
                            donationProvider,
                            parentTransactionId: user.walletTransactions?.[0]?.transactionId,
                            transactionId,
                            [deed ? 'deed' : 'nonprofit']: deed?.id || firstNonprofit?.id,
                            amount: deedBalanceAmountSelected,
                            desiredMatchAmount: matchData?.desiredMatchAmount,
                            desiredMatchingRule: matchData?.desiredMatchingRule,
                            coverFee: checkedCoverFee,
                            currency: user.donationCreditsWallet?.currencyCode,
                            privacy,
                            company: user.organization?.id,
                            donationPaymentMethod,
                            campaign: currentCampaign?.id,
                            feeCovered: user?.feeCovered,
                            designation,
                            dedication,
                            feesHistoryItem,
                          })
                        )
                      }
                      feesHistoryItem={feesHistoryItem}
                      desiredMatchAmount={matchData?.desiredMatchAmount}
                      desiredMatchingRule={matchData?.desiredMatchingRule}
                    />
                  </>
                )}
              </>
            )}

            <Spacing marginBottom={BASE_SPACING * 0.75} />

            {paymentType === PaymentMethodType.Payroll ? (
              <>
                <H5 weight="500" marginBottom={16}>{t`taxBenefit`}</H5>

                <Body2>{corporateProgram?.metadata?.taxBenefitInformation || t`ifYouChooseToMake`}</Body2>

                <DonateButton
                  color={deedBalanceAmountSelectedValid ? 'primary' : 'light'}
                  disabled={!deedBalanceAmountSelectedValid && !amountValid}
                  size="small"
                  onClick={() => {
                    dispatch(
                      actions.donateAction({
                        donationProvider,
                        amount: amountSelected,
                        desiredMatchAmount: matchData?.desiredMatchAmount,
                        desiredMatchingRule: matchData?.desiredMatchingRule,
                        currency: baseCurrency,
                        coverFee: checkedCoverFee,
                        [deed ? 'deed' : 'nonprofit']: deed?.id || nonprofitId,
                        privacy,
                        donorPrivacy,
                        designation,
                        dedication,
                        donor,
                        campaign: currentCampaign?.id,
                        donationPaymentMethod,
                        frequency: donationFrequency === DonationFrequency.Monthly ? DonationFrequency.Monthly : null,
                        feeCovered: user?.feeCovered,
                        payrollPaymentOptionCode: String(selectedPayrollPaymentOption?.code),
                      })
                    )
                  }}
                >
                  {t`donate`}
                </DonateButton>
              </>
            ) : (
              !giveIndiaCommited && (
                <>
                  {donationProvider === DonationProvider.GiveIndia && (
                    <Body2 marginBottom={BASE_SPACING * 0.75}>
                      <Trans
                        t={t}
                        i18nKey="giveIndiaDisclosure"
                        components={{
                          DisclosureLink: (
                            <ExternalLink
                              size={14}
                              underline
                              href="https://fundraisers.giveindia.org/terms-conditions"
                            />
                          ),
                        }}
                      />
                    </Body2>
                  )}
                  <TaxReceipts providerData={providerData} nonprofitNamesFormatted={nonprofitNamesFormatted} />
                </>
              )
            )}

            <Spacing marginBottom={BASE_SPACING * 5} />
          </View>
        </View>
      </Layout>
    </>
  )
}

export default Donate
