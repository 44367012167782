import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { Button, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'

import VolunteerTime from 'src/entities/volunteerTime/model'
import { EligibilityFormStatus, VolunteerTimeApproval } from 'src/generated/graphql'
import { useHistory } from 'src/navigation'
import { ActivityIndicator, Row, Touchable } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { convertDuration } from 'src/utils/convertDuration'
import Icon from 'src/retired/shared/Icon'

import { StatusChip, StatusChipStatuses } from '../StatusChip'

export const VolunteerTimesTable = ({
  volunteerTimes,
  isDeleting,
  lastDeletedVolunteerTimeId,
  deleteVolunteerTime,
  editVolunteerTime,
  isVolunteerRewardEnabled,
}: {
  volunteerTimes: VolunteerTime[]
  isDeleting: boolean
  lastDeletedVolunteerTimeId?: string
  deleteVolunteerTime: (volunteerTimeId: string) => void
  editVolunteerTime: (volunteerTimeId: string) => void
  isVolunteerRewardEnabled: boolean | undefined
}) => {
  const { t } = useTranslation('volunteerTimesProfile')
  const { metrics, colors } = useDeedTheme()
  const history = useHistory()

  const navigateToVolunteerRewardFlow = (volunteerTime: VolunteerTime) => {
    history.push(
      `/profile/volunteer-hours/success/${volunteerTime.id}${volunteerTime.VolunteerTimeOff?.id ? '/vto' : ''}`
    )
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 0, borderRadius: 0 }}>
      <TableContainer
        sx={{
          maxHeight: 440,
          '& .MuiTableCell-root': {
            padding: { sm: '8px', md: '16px' },
          },
          '& .MuiTableRow-root > th.MuiTableCell-root:first-of-type': {
            paddingLeft: { sm: '16px', md: '24px' },
          },
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="volunteer hours table">
          <TableHead>
            <TableRow>
              <TableCell size="small">
                <Body2 weight="500">{t`opportunities`}</Body2>
              </TableCell>
              <TableCell>
                <Body2 weight="500" center>{t`volunteeringDate`}</Body2>
              </TableCell>
              <TableCell>
                <Body2 weight="500" center>{t`loggedHours`}</Body2>
              </TableCell>
              <TableCell>
                <Body2 weight="500" center>{t`loggedHoursStatus`}</Body2>
              </TableCell>
              {isVolunteerRewardEnabled ? (
                <TableCell>
                  <Body2 weight="500" center>
                    <Tooltip title={t`grantStatusTooltip`} enterTouchDelay={0}>
                      <div style={{ display: 'flex' }}>
                        {t`grantApplication`}
                        <div style={{ paddingLeft: 3 }}>
                          <Icon icon="infoCircleBlack" width={11} height={11} />
                        </div>
                      </div>
                    </Tooltip>
                  </Body2>
                </TableCell>
              ) : null}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {volunteerTimes.map((volunteerTime) => (
              <TableRow key={volunteerTime.id}>
                <TableCell component="th" scope="row">
                  <Touchable
                    onPress={() => history.push(`/profile/volunteer-hours/${volunteerTime.id}`)}
                    key={volunteerTime.id}
                  >
                    <Row style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                      <Body2
                        style={{
                          maxWidth: metrics.isSmall ? 150 : 200,
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {volunteerTime.deed?.name ||
                          t('externalVolunteeringForNonprofit', {
                            nonprofitName: volunteerTime.nonprofit?.name || volunteerTime.externalNonprofitName,
                          })}
                        {volunteerTime.Role ? ` - ${volunteerTime.Role.name}` : ''}
                      </Body2>
                    </Row>
                  </Touchable>
                </TableCell>
                <TableCell>
                  <Body2 center>{t('date:dayMonthYearShort', { date: volunteerTime.date })}</Body2>
                </TableCell>
                <TableCell>
                  <Body2 center>{convertDuration(volunteerTime.duration)}</Body2>
                  {volunteerTime.approval === VolunteerTimeApproval.Pending && volunteerTime.duration < 60 && (
                    <Tooltip title={t`tooltipEdit`}>
                      <IconButton onClick={() => editVolunteerTime(volunteerTime.id)} key={`edit-${volunteerTime.id}`}>
                        <EditIcon fontSize="small" style={{ color: colors.gray }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <StatusChip
                    label={t('volunteerTime', { context: volunteerTime.approval })}
                    status={StatusChipStatuses[volunteerTime.approval]}
                    colors={colors}
                  />
                </TableCell>
                {isVolunteerRewardEnabled && (
                  <TableCell align="center">
                    {volunteerTime.approval === VolunteerTimeApproval.Rejected ||
                    volunteerTime.duration < 60 ||
                    volunteerTime.VolunteerReward?.formStatus === EligibilityFormStatus.NotRequired ? (
                      t`notAvailable`
                    ) : volunteerTime.VolunteerReward?.formStatus === EligibilityFormStatus.Eligible ? (
                      t`applied`
                    ) : volunteerTime.VolunteerReward?.formStatus === EligibilityFormStatus.NotEligible ? (
                      t`notEligible`
                    ) : volunteerTime.VolunteerReward?.formStatus === EligibilityFormStatus.Pending &&
                      volunteerTime.duration >= 60 ? (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => navigateToVolunteerRewardFlow(volunteerTime)}
                        sx={{ borderRadius: 16 }}
                        disableElevation
                      >{t`apply`}</Button>
                    ) : (
                      t`notAvailable`
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {volunteerTime.approval === VolunteerTimeApproval.Pending && (
                    <div>
                      {isDeleting && lastDeletedVolunteerTimeId === volunteerTime.id ? (
                        <ActivityIndicator />
                      ) : (
                        <Tooltip title={t`tooltipDelete`}>
                          <IconButton
                            onClick={() => {
                              deleteVolunteerTime(volunteerTime.id)
                            }}
                          >
                            <DeleteOutlineIcon fontSize="small" style={{ color: colors.gray }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
