import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useHistory } from 'react-router'

import { CountOfUserRolesOnDeed } from 'src/containers/modules/DeedCancel'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import truthy from 'src/utils/truthy'

export const ShiftButtons = ({ deed, user }: { deed: Deed; user: User | undefined }) => {
  const { t } = useTranslation('deedScreen')
  const { colors, metrics } = useDeedTheme()
  const history = useHistory()
  const isMultipleShiftsFeatureEnabledForEvent = user?.hasFeature('multipleShifts') && deed?.type === 'Event'

  const navigateToShifts = (type: 'confirm' | 'cancel') => {
    history.push(`/deeds/${deed.id}/${type}`)
  }

  if (isMultipleShiftsFeatureEnabledForEvent && metrics.isLarge) {
    const numberOfRolesUserApplied = (user && deed.numberOfRolesUserApplied(user.id)) || 0
    const isPastRegistrationDeadline = deed.registrationEndingAt && deed.registrationEndingAt < new Date()
    const isComplete = user && user.hasCompletedDeed?.(deed.id)
    const canUserLogHours = user && deed.canUserLogHours(user)

    console.log('numberOfRolesUserApplied', numberOfRolesUserApplied)
    if (isPastRegistrationDeadline || isComplete || deed.status !== 'published' || canUserLogHours || deed.isPast()) {
      return null
    }

    const showCancelButton = numberOfRolesUserApplied > 0
    const showSignUpToRoleButton = deed.roles?.size > 0 && user && deed.hasAnyRoleToApplyLeft(user.id)
    const buttonsToShow = [showCancelButton, showSignUpToRoleButton].filter(truthy)

    const styles = {
      shiftButton: {
        borderRadius: 32,
        flex: buttonsToShow.length === 2 ? 1 : undefined,
        width: buttonsToShow.length === 2 ? undefined : '50%',
      },
      wrapper: {
        flexDirection: metrics.isSmall ? 'column' : 'row',
        justifyContent: 'center',
        columnGap: 16,
        marginTop: '20px',
      },
    }

    return (
      <View style={styles.wrapper}>
        {showCancelButton ? (
          <Button
            variant="outlined"
            onClick={() => navigateToShifts('cancel')}
            style={styles.shiftButton}
            disableElevation
          >
            {t`cancelShifts`}
            {numberOfRolesUserApplied ? (
              <CountOfUserRolesOnDeed
                number={numberOfRolesUserApplied}
                styles={{ backgroundColor: colors.brandColor, color: colors.white }}
              />
            ) : null}
          </Button>
        ) : null}
        {showSignUpToRoleButton ? (
          <Button
            variant="contained"
            onClick={() => navigateToShifts('confirm')}
            style={styles.shiftButton}
            disableElevation
          >
            {numberOfRolesUserApplied > 0 ? t`signUpForMoreShifts` : t`signUpForShifts`}
          </Button>
        ) : null}
      </View>
    )
  } else {
    return null
  }
}
