import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { Link, useHistory } from 'src/navigation'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { Action, Divider, Loading, Screen, ScrollView, Spacing } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Body2, H4, Label } from 'src/retired/shared/Typography'
import SelectBox from 'src/retired/shared/SelectBox'
import Button from 'src/retired/shared/Button'
import Deed from 'src/entities/deed/model'
import Role from 'src/entities/deed/role'
import { selectPastDeedsForUser, selectOngoingDeedsForUser } from 'src/entities/deed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { getLanguageCode } from 'src/utils/languageUtils'
import Icon from 'src/retired/shared/Icon'
import { PageTitle } from 'src/components'

import { selectLoading, selectError } from './selectors'
import { initAction } from './actions'
import reducer from './reducer'
import epics from './epics'

const Header = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '0 80px 20px 55px' : '0 25px 25px 25px')};
`

const FormContainer = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '20px 195px 70px' : '25px')};
  width: 100%;
  margin-bottom: 130px;
`

const ButtonContainer = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '20px 195px 70px' : '25px')};
  width: 100%;
`

const TextDivider = styled.Text<object, EmotionTheme>`
  margin: -35px auto 0;
  color: ${({ theme }) => theme.colors.mediumGray};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 10px;
  z-index: 1;
`

const VolunteerTimeSelectDeed = (): JSX.Element => {
  useInjectEpics({ key: 'volunteerTimeSelectDeed', epics })
  useInjectReducer({ key: 'volunteerTimeSelectDeed', reducer })
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const user = useSelector(selectCurrentUser)
  const pastDeeds = useSelector((state) => selectPastDeedsForUser(state, user))
  const ongoingDeeds = useSelector((state) => selectOngoingDeedsForUser(state, user))

  const { colors } = useDeedTheme()
  const history = useHistory()

  const [selectedDeed, setSelectedDeed] = useState<Deed>()
  const [userRolesOnSelectedDeed, setUserRolesOnSelectedDeed] = useState<Role[]>()
  const [selectedRoleId, setSelectedRoleId] = useState<string>()

  const { t, i18n } = useTranslation('volunteerTimeSelectedDeedProfile')

  useEffect(() => {
    dispatch(initAction())
  }, [])

  const sortedDeeds = [...ongoingDeeds.toArray(), ...pastDeeds.toArray()]
    .filter((deed) => deed.type !== 'BaseEvent')
    .sort((a, b) => b.startingAt || b.createdAt - a.startingAt || a.createdAt)

  const getSelectedDeedById = (deedId?: string) => (deedId ? sortedDeeds.find((deed) => deed.id === deedId) : undefined)

  useEffect(() => {
    const newRoles =
      selectedDeed?.roles
        ?.toArray()
        .filter((role) =>
          (role.approved ?? []).find((approvedUser) =>
            [approvedUser?.id, approvedUser].filter(Boolean).includes(user?.id)
          )
        ) ?? []

    setUserRolesOnSelectedDeed(newRoles)
    setSelectedRoleId(newRoles[0]?.id)
  }, [selectedDeed])

  return (
    <Screen fixed>
      <ScrollView>
        <NavigationHeader transparent />
        <Header>
          <Label marginBottom={8} center weight="500" colour={colors.brandColor}>
            {t`logHoursFor`.toLocaleUpperCase(getLanguageCode(i18n.language))}
          </Label>

          <H4 center marginBottom={8}>
            {t`selectTheDeedYouVolunteeredFor`}
          </H4>

          <Body2 center>{t`letsLogYourVolunteerSessions`}</Body2>
        </Header>

        {loading ? (
          <Spacing marginBottom={180} marginTop={30}>
            <Loading fill={false} />
          </Spacing>
        ) : error ? (
          <Spacing marginBottom={180} marginTop={30}>
            <PageTitle title={t`thereWasAnErrorLoadingYourDeeds`} />
            <Body2 lineHeight={21} center>
              {t`thereWasAnErrorLoadingYourDeeds`}
            </Body2>
          </Spacing>
        ) : (
          <FormContainer>
            <PageTitle title={t`selectTheDeedYouVolunteeredFor`} />
            {sortedDeeds.length ? (
              <>
                <Body2>{t`common:deed`}</Body2>
                <SelectBox
                  onSelect={(value) => setSelectedDeed(getSelectedDeedById(value))}
                  value={selectedDeed?.id}
                  placeholder={t`selectPastOrOngoingDeedHere`}
                  options={sortedDeeds.map((deed) => ({
                    value: deed.id,
                    title: deed.name,
                  }))}
                  style={{
                    marginTop: 8,
                    marginBottom: 16,
                    minWidth: 'auto',
                    width: '100%',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
                {userRolesOnSelectedDeed?.length ? (
                  <>
                    <Body2>
                      {t('common:role', {
                        context: selectedDeed?.get('type') === 'Event' ? 'shift' : undefined,
                      })}
                    </Body2>
                    <SelectBox
                      onSelect={(value: string) => setSelectedRoleId(value)}
                      value={selectedRoleId}
                      placeholder={t('volunteerTimeFormProfile:chooseARole', {
                        context: selectedDeed?.get('type') === 'Event' ? 'shift' : undefined,
                      })}
                      options={userRolesOnSelectedDeed.map((role) => ({
                        value: role.id,
                        title: role.name,
                      }))}
                      style={{
                        marginTop: 8,
                        marginBottom: 16,
                        minWidth: 'auto',
                        width: '100%',
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    />
                  </>
                ) : null}
              </>
            ) : (
              <Body2 center marginBottom={20}>{t`youHaveNotAttendedAnyDeedYet`}</Body2>
            )}
            {user?.hasFeature('userLogOfflineHours') && (
              <>
                <Divider />

                <TextDivider>{t`common:Or`.toLocaleUpperCase(getLanguageCode(i18n.language))}</TextDivider>

                <Link to="/profile/volunteer-hours/select-nonprofit" style={{ textAlign: 'center', marginTop: 30 }}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: colors.purple,
                      borderRadius: 100,
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 24,
                      paddingRight: 24,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Body2 colour={colors.purple}>{t`iHaveVolunteeredOutsideOfDeed`}</Body2>
                    <Icon icon="externalResource" width={16} height={16} />
                  </View>
                </Link>
              </>
            )}
          </FormContainer>
        )}
      </ScrollView>
      <Action>
        <ButtonContainer>
          <Button
            onPress={() =>
              history.push(
                `/profile/volunteer-hours/add/${selectedDeed?.id}/deed${
                  selectedRoleId ? `?role=${selectedRoleId}` : ''
                }`
              )
            }
            palette="primary"
            loading={false}
            style={{ justifyContent: 'center' }}
            disabled={!selectedDeed?.id || (selectedDeed?.get('roles')?.size && !selectedRoleId)}
            rounded
          >
            {t`common:Next`}
          </Button>
        </ButtonContainer>
      </Action>
    </Screen>
  )
}

VolunteerTimeSelectDeed.displayName = 'VolunteerTimeSelectDeed'
export default VolunteerTimeSelectDeed
