import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { ColorsProps } from 'src/theme/colors'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { Body1 } from 'src/retired/shared/Typography'
import { Avatar, Row, ScrollView } from 'src/retired/elements'

import { Box } from './MapBlock'

export const AttendeesBlock = ({
  deed,
  colors,
  styles,
  user,
}: {
  deed: Deed
  colors: ColorsProps
  styles: React.CSSProperties
  user?: User
}): React.ReactNode => {
  const { t } = useTranslation('deedScreen')
  const { attendees } = deed
  if (!attendees || attendees.length === 0) {
    return null
  }

  return (
    <Box half>
      <View style={{ width: '100%', marginBottom: 20 }}>
        <Row style={{ alignItems: 'center' }}>
          <Body1 weight="500">{`${attendees.length} ${t('attending')}, `}</Body1>
          {['BaseEvent', 'Event', 'Project'].includes(deed.type) && (
            <Body1 weight="500" colour={colors.brandColor}>
              {t('spotsLeft', { count: deed.numberOfSpotsAvailable() })}
            </Body1>
          )}
        </Row>

        <ScrollView
          horizontal
          style={[styles.scrollView, { marginTop: 15 }]}
          contentContainerStyle={styles.scrollViewContainer}
        >
          {attendees.map((attendee) => (
            <Avatar
              key={attendee.id}
              user={attendee}
              link={user && String(attendee.id) !== String(user.id)}
              style={{ borderWidth: attendee.clickable ? 2 : 0, borderColor: colors.blue }}
            />
          ))}
        </ScrollView>
      </View>
    </Box>
  )
}
